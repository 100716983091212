import { useEffect, MutableRefObject, useRef, useCallback } from 'react';
import { usePrevious, usePersistFn } from '../../../hooks';
import { isArrayShallowEqual } from '../../../utils/util';
import { CellLayout } from '../video-types';
import { MediaStream, Participant } from '../../../index-types';
import { SELF_VIDEO_ID } from '../video-constants';
/**
 * gallery view without SharedArrayBuffer mode, self video is present by Video Element
 */
export function useRenderVideo(
  mediaStream: MediaStream | null,
  isVideoDecodeReady: boolean,
  videoRef: MutableRefObject<HTMLCanvasElement | null>,
  layout: CellLayout[],
  subscribedVideos: number[],
  participants: Participant[],
  currentUserId?: number
) {
  const renderedVideos = subscribedVideos.slice(0, layout.length);
  const previousRenderedVideos = usePrevious(renderedVideos);
  const previousLayout = usePrevious(layout);
  const previousParticipants = usePrevious(participants);
  const renderQueue = useRef<Set<number>>(new Set());
  const isRendering = useRef(false);
  const renderTimeout = useRef<NodeJS.Timeout>();

  const processRenderQueue = useCallback(async () => {
    if (isRendering.current || renderQueue.current.size === 0) return;
    
    isRendering.current = true;
    const userId = Array.from(renderQueue.current)[0];
    renderQueue.current.delete(userId);

    try {
      const index = participants.findIndex((user) => user.userId === userId);
      const cellDimension = layout[index];
      
      if (cellDimension) {
        let canvas: HTMLCanvasElement | HTMLVideoElement = videoRef.current as HTMLCanvasElement;
        if (mediaStream?.isRenderSelfViewWithVideoElement() && userId === currentUserId) {
          canvas = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement;
        }
        
        const { width, height, x, y, quality } = cellDimension;
        
        // Always re-render when aspect ratio changes
        await mediaStream?.renderVideo(canvas, userId, width, height, x, y, quality);
        
        const isSkip = mediaStream?.isRenderSelfViewWithVideoElement() && userId === currentUserId;
        if (!isSkip) {
          await mediaStream?.adjustRenderedVideoPosition(canvas, userId, width, height, x, y);
        }
      }
    } catch (error) {
      console.warn('Failed to render video:', error);
      // Add back to queue if failed
      renderQueue.current.add(userId);
    } finally {
      isRendering.current = false;
    }
  }, [mediaStream, layout, participants, currentUserId, videoRef]);

  useEffect(() => {
    if (videoRef.current && layout && layout.length > 0 && isVideoDecodeReady) {
      // Clear any pending timeouts
      if (renderTimeout.current) {
        clearTimeout(renderTimeout.current);
      }

      // Handle new subscribers
      const newSubscribers = renderedVideos.filter((userId) => !previousRenderedVideos?.includes(userId));
      newSubscribers.forEach((userId) => {
        renderQueue.current.add(userId);
      });

      // Handle layout changes for existing subscribers
      const unalteredSubscribers = renderedVideos.filter((userId) => previousRenderedVideos?.includes(userId));
      if (unalteredSubscribers.length > 0 && previousLayout && !isArrayShallowEqual(layout, previousLayout)) {
        unalteredSubscribers.forEach((userId) => {
          renderQueue.current.add(userId);
        });
      }

      // Process render queue with a small delay to allow for aspect ratio updates
      renderTimeout.current = setTimeout(() => {
        processRenderQueue();
      }, 100);
    }
  }, [
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    layout,
    renderedVideos,
    previousRenderedVideos,
    previousLayout,
    participants,
    currentUserId,
    processRenderQueue
  ]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      renderQueue.current.clear();
      isRendering.current = false;
      if (renderTimeout.current) {
        clearTimeout(renderTimeout.current);
      }
    };
  }, []);

  return {
    processRenderQueue
  };
}
